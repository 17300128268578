import angular from 'angular';
import introJs from 'intro.js';
import userflow from 'userflow.js';
import MODULE from './module';
// templates
import profileTemplate from './profile.html';
import loginTemplate from './login.html';
import logoutTemplate from './logout.html';
import registerTemplate from './register.html';
import activateTemplate from './activate.html';
import productTrialActivateTemplate from './product-trial-activate.html';
import forgotPasswordTemplate from './forgot-password.html';
import resetPasswordTemplate from './reset-password.html';
import inviteAcceptTemplate from './invite-accept.html';
import notAuthorizedTemplate from './not-authorized.html';
import waitingAssignmentTemplate from './waiting-assignment.html';
import publicTournamentRegisterTemplate from './public-tournament-register.html';
import { AUTH_EVENTS, SESSION_HEADER, SESSION_KEY, USER_ROLES, USER_STATUS, USER_TYPE } from './constants';
import { getAmplitudeSessionId } from '../analytics/amplitude-client';
import sSOAcceptTemplate from './sso-terms-and-conditions-accept.html';
import { react_router_states } from '../scw-react/enums';

const app = angular.module(MODULE);

// auth event constants for broadcast messages
app.constant('AUTH_EVENTS', AUTH_EVENTS);
app.constant('USER_ROLES', USER_ROLES);
app.constant('USER_STATUS', USER_STATUS);
app.constant('USER_TYPE', USER_TYPE);
app.constant('SESSION_HEADER', SESSION_HEADER);
app.constant('SESSION_KEY', SESSION_KEY);

app.config([
  '$stateProvider',
  'USER_ROLES',
  function ($stateProvider, USER_ROLES) {
    $stateProvider
      .state('profile', {
        url: '/profile',
        templateUrl: profileTemplate,
        controller: 'ProfileController',
        data: {
          authorizedRoles: [
            USER_ROLES.player,
            USER_ROLES.manager,
            USER_ROLES.companyAdmin,
            USER_ROLES.reseller,
            USER_ROLES.admin,
            USER_ROLES.assessmentUser,
          ],
        },
      })
      .state('sso-login', {
        url: '/sso/{token}?domain&RelayState&ccdata&cciv',
        templateUrl: loginTemplate,
        controller: 'LoginController',
      })
      .state('login', {
        url: '/login?eid&d',
        templateUrl: loginTemplate,
        controller: 'LoginController',
      })
      .state('login-catchroot', {
        url: '',
        templateUrl: loginTemplate,
        controller: 'LoginController',
      })
      .state('login-catchslash', {
        url: '/',
        templateUrl: loginTemplate,
        controller: 'LoginController',
      })
      .state('tech-channels', {
        url: '/tech-channels',
        templateUrl: loginTemplate,
        controller: 'LoginController',
      })
      .state('logout', {
        url: '/logout',
        templateUrl: logoutTemplate,
        controller: 'LogoutController',
      })
      .state('register', {
        url: '/register',
        templateUrl: registerTemplate,
        controller: 'RegisterController',
      })
      .state('registerToken', {
        url: '/register/:token',
        templateUrl: registerTemplate,
        controller: 'RegisterController',
      })
      .state('bc-activate', {
        url: '/activate/{email}/{token}',
        templateUrl: activateTemplate,
        controller: 'ActivateController',
      })
      .state('activate', {
        url: '/activate/{token}',
        templateUrl: activateTemplate,
        controller: 'ActivateController',
      })
      .state('product-trial-activate', {
        url: '/product-trial/activate/{token}',
        templateUrl: productTrialActivateTemplate,
        controller: 'ProductTrialActivateController',
      })
      .state('forgot-password', {
        url: '/forgot-password',
        templateUrl: forgotPasswordTemplate,
        controller: 'ForgotPasswordController',
      })
      .state('bc-reset-password', {
        url: '/reset/{email}/{token}',
        templateUrl: resetPasswordTemplate,
        controller: 'ResetPasswordController',
      })
      .state('reset-password', {
        url: '/reset/{token}',
        templateUrl: resetPasswordTemplate,
        controller: 'ResetPasswordController',
      })
      .state('invite-accept', {
        url: '/invite-accept/{token}?assessment&attempt',
        templateUrl: inviteAcceptTemplate,
        controller: 'InviteAcceptController',
      })
      .state('bc-invite-accept', {
        url: '/invite-accept/{email}/{token}?assessment&attempt',
        templateUrl: inviteAcceptTemplate,
        controller: 'InviteAcceptController',
      })
      .state('not-authorized', {
        url: '/not-authorized',
        templateUrl: notAuthorizedTemplate,
      })
      .state('waiting-assignment', {
        url: '/waiting-assignment',
        templateUrl: waitingAssignmentTemplate,
      })
      .state('user-registration', {
        url: '/user-registration',
        templateUrl: publicTournamentRegisterTemplate,
        controller: 'PublicTournamentRegistrationController',
      })
      .state('sso-terms-and-conditions-accept', {
        url: '/sso-accept/{endpoint}/{encodedToken}/{encodedIv}/{encodedHmac}?RelayState&ccdata&cciv&i18n',
        templateUrl: sSOAcceptTemplate,
        controller: 'SSOAcceptController',
      })
      .state('sso-terms-and-conditions-accept-token', {
        url: '/sso-accept/{acceptToken}',
        templateUrl: sSOAcceptTemplate,
        controller: 'SSOAcceptController',
      });
  },
]);

function defaultRedirects($state, AuthService, Session, USER_ROLES) {
  const senseiAuthToken = AuthService.getSenseiAuthToken();

  if (!Session.user) {
    $state.go('login');
    return;
  }

  if (senseiAuthToken) {
    $state.go('sensei-auth');
    return;
  }

  if (AuthService.hasTrustAgentProvider()) {
    $state.go('trust-agent');
    return;
  }

  if (
    !Session.user.properties.profile.isComplete &&
    !Session.isAnonymous() &&
    AuthService.isAuthorized(USER_ROLES.admin)
  ) {
    $state.go('profile'); //redirect to the profile page for the SCW admin
    return;
  }

  if (!Session.user.properties.profile.isComplete && !Session.isAnonymous()) {
    $state.go('home');
    return;
  }

  if (AuthService.isAuthorized(USER_ROLES.player) && AuthService.isFeatureEnabled('training')) {
    $state.go('home');
  } else if (AuthService.isAuthorized(USER_ROLES.assessmentUser) && AuthService.isFeatureEnabled('assessments')) {
    $state.go('assessments.list');
  } else if (AuthService.isAuthorized(USER_ROLES.admin)) {
    $state.go('admin.companies');
  } else if (AuthService.isAuthorized(USER_ROLES.reseller)) {
    $state.go('admin.companies');
  } else if (AuthService.isAuthorized(USER_ROLES.companyAdmin)) {
    $state.go('home');
  } else if (AuthService.isAuthorized(USER_ROLES.manager)) {
    $state.go('home');
  } else {
    $state.go('home');
  }
}

app.service('UserflowService', [
  '$http',
  '$window',
  'HttpConfigService',
  '$log',
  function ($http, $window, HttpConfigService, $log) {
    this.execute = function () {
      const httpConfig = HttpConfigService.getHttpConfigNoIFR();
      const { SCW_ENV } = $window;
      return $http
        .get(SCW_ENV.ApiEndpoint + '/me/gtm/attributes', httpConfig)
        .then(function (response) {
          let customAttributes = response.data;
          customAttributes = { ...customAttributes, AmplitudeSessionId: getAmplitudeSessionId($window) };

          userflow.init($window.SCW_ENV.USERFLOW_TOKEN);
          userflow.identify(customAttributes.UserIdHash, {
            name: customAttributes.Name,
            email: customAttributes.Email,
            signed_up_at: customAttributes.SignUpDate,
            session_id: customAttributes.AmplitudeSessionId,
            roles: customAttributes.Roles,
            languages: customAttributes.Languages,
            preferred_language: customAttributes.PreferredLanguage,
            training_minutes_spent: customAttributes.TrainingMinutesSpent,
            training_points: customAttributes.TrainingPoints,
            last_seen: customAttributes.LastSeen,
            spoken_language: customAttributes.SpokenLanguage,
            sign_up_date: customAttributes.SignUpDate,
            invite_source: customAttributes.InviteSource,
            next_best_task_link: customAttributes.NextTaskLink,
            next_best_task_name: customAttributes.NextTaskName,
            next_best_task_type: customAttributes.NextTaskType,
            team_hash: customAttributes.TeamIdHash,
          });
          userflow.group(customAttributes.CompanyIdHash, {
            name: customAttributes.CompanyName,
            salesforce_id: customAttributes.SalesforceId,
            customer_plan: customAttributes.CustomerPlan,
          });
        })
        .catch(function (err) {
          $log.log('[Userflow Error]:', err);
        });
    };
    this.reset = function () {
      userflow.reset();
    };
  },
]);

app.service('UserPreferences', [
  '$log',
  '$http',
  '$rootScope',
  '$window',
  '$translate',
  'Session',
  'ErrorHandler',
  'HttpConfigService',
  function ($log, $http, $rootScope, $window, $translate, Session, ErrorHandler, HttpConfigService) {
    const holder = {
      preferences: {},
    };
    const { SCW_ENV } = $window;
    this.get = function (key, defaultValue) {
      const value = _.get(holder.preferences, key);
      return value !== undefined && value !== null ? value : defaultValue;
    };

    this.save = function (key, value) {
      $log.debug('Saving user preference', key, value);
      const path = key.split('.');
      let curr = holder.preferences;

      for (let i = 0; i < path.length; i++) {
        if (i === path.length - 1) {
          curr[path[i]] = value;
          break;
        }

        // if preference node does not exist, create it
        if (!curr[path[i]]) {
          curr[path[i]] = {};
        }
        curr = curr[path[i]];
      }
      delete holder.preferences._id;
      $log.debug('Sending preference set to server', holder.preferences);
      return this.saveAll(holder.preferences);
    };

    this.saveAll = function saveAll(preferences) {
      preferences = preferences || holder.preferences;
      return $http
        .post(SCW_ENV.ApiEndpoint + '/me/preferences', preferences, HttpConfigService.getHttpConfig())
        .then(function (response) {
          $rootScope.$emit('user-preferences:updated', preferences);
          holder.preferences = response.data;
        });
    };

    this.load = function load(cb) {
      $log.debug('Loading preferences for user ' + Session.getUser().email);
      return $http
        .get(SCW_ENV.ApiEndpoint + '/me/preferences', HttpConfigService.getHttpConfig())
        .then(function (response) {
          $rootScope.$emit('user-preferences:loaded', response.data);
          $log.debug('Fetched user preferences: ', response.data);
          holder.preferences = response.data || {};
          holder.loaded = true;

          if (cb) {
            return cb(response.data || {});
          }

          return response.data || {};
        })
        .catch(function (response) {
          $log.debug('ERROR while fetching user preferences', response);
          $translate(['FAILED_TO_LOAD_USER_PREFERENCES']).then(function (translations) {
            ErrorHandler.addHttpError(translations.FAILED_TO_LOAD_USER_PREFERENCES, response);
          });
        });
    };
  },
]);

// catch state change events and check auth
app.run([
  '$window',
  '$location',
  '$log',
  '$rootScope',
  '$urlRouter',
  'AUTH_EVENTS',
  'USER_ROLES',
  'AuthService',
  'Session',
  '$state',
  '$translate',
  'ErrorHandler',
  'AdminApiService',
  'AnalyticsService',
  'AnalyticsEvents',
  function (
    $window,
    $location,
    $log,
    $rootScope,
    $urlRouter,
    AUTH_EVENTS,
    USER_ROLES,
    AuthService,
    Session,
    $state,
    $translate,
    ErrorHandler,
    AdminApiService,
    AnalyticsService,
    AnalyticsEvents
  ) {
    $rootScope.sessionResolved = false;

    $rootScope.$on(AUTH_EVENTS.loginSuccess, function (_e, user) {
      const licenseWarningTS = new Date().getTime() + 14 * 24 * 3600 * 1000;
      $rootScope.licenseWarningTS = { val: licenseWarningTS };
      $rootScope.isLicenseExpired = user?.expirationDate < Date.now();
    });

    $rootScope.$on('$locationChangeStart', function (_event, next, _current) {
      const nextPath = new URL(next).hash.slice(1);

      if ($rootScope.actualLocation === nextPath) {
        $('#senseiToastyContainer').remove();
        introJs().exit();
        setTimeout(function () {
          swal.close();
        }, 0);

        $rootScope.currentStep = 0;
        if (!$state.current.abstract) $state.go($state.current, {}, { reload: true });
      }
    });

    $rootScope.$on('$locationChangeSuccess', function () {
      $rootScope.actualLocation = $location.path();
    });

    $rootScope.$on('$stateChangeStart', function (event, next, nextParams, from, _fromParams) {
      $log.debug('on $stateChangeStart', from.name, next.name, $rootScope.sessionResolved);

      if (!$rootScope.sessionResolved) {
        $rootScope.sessionResolved = true;
        if (!AuthService.isAuthenticated()) {
          event.preventDefault();
          var sessionId = Session.getSessionId();
          if (sessionId && sessionId !== 'undefined') {
            $log.debug('Attempting to resume previous session: ', sessionId);

            $rootScope.requestedState = next.name;
            $rootScope.requestedStateParams = nextParams;

            AuthService.addLoading();
            AuthService.resumeSession()
              .then(function (data) {
                $rootScope.$broadcast(AUTH_EVENTS.loginSuccess, data);
                $log.debug('Resume session successful');
                var UserStatus = $rootScope.metadata.constants.user.status;
                var activeStatuses = [UserStatus.ENABLED, UserStatus.REGISTERED];

                if (data.status === UserStatus.ACTIVATION_TIMED_OUT) {
                  $translate([
                    'OK',
                    'ACCOUNT_NOT_ACTIVATED_TITLE',
                    'ACCOUNT_NOT_ACTIVATED',
                    'RESEND',
                    'CANCEL',
                    'ACTIVATION_EMAIL_RESENT',
                    'ACTIVATION_EMAIL_RESENT_DESC',
                    'FAILED_TO_RESEND_ACTIVATION_EMAIL',
                  ]).then(function (translations) {
                    swal(
                      {
                        title: translations.ACCOUNT_NOT_ACTIVATED_TITLE,
                        text: translations.ACCOUNT_NOT_ACTIVATED,
                        type: 'error',
                        showCancelButton: true,
                        confirmButtonText: translations.RESEND,
                        cancelButtonText: translations.CANCEL,
                        html: true,
                        closeOnConfirm: false,
                      },
                      function (isConfirm) {
                        if (isConfirm) {
                          AuthService.addLoading();
                          AuthService.resendActivation({ email: data.email })
                            .then(function (_data) {
                              swal({
                                title: translations.ACTIVATION_EMAIL_RESENT,
                                text: translations.ACTIVATION_EMAIL_RESENT_DESC,
                                type: 'success',
                                confirmButtonText: translations.OK,
                              });
                            })
                            .catch(function (_data) {
                              ErrorHandler.addError(translations.FAILED_TO_RESEND_ACTIVATION_EMAIL);
                            })
                            .finally(function () {
                              AuthService.removeLoading();
                            });
                        }
                      }
                    );
                  });
                } else if (data.status === UserStatus.READ_ONLY) {
                  $translate(['LICENSE_INACTIVE', 'LICENSE_INACTIVE_TEXT']).then(function (translations) {
                    swal(translations.LICENSE_INACTIVE, translations.LICENSE_INACTIVE_TEXT, 'info');
                  });
                  $state.go('home');
                } else if (data.status === UserStatus.DISABLED) {
                  $translate('ACCOUNT_NOT_ENABLED').then(function (errorText) {
                    var errMsg = data.statusMsg || errorText;
                    ErrorHandler.addError(errMsg);
                  });
                } else if (activeStatuses.indexOf(data.status) > -1) {
                  Session.create(sessionId, data, Session.isSessionRemembered());
                  $log.debug('Resume session complete', data);
                  if ($state.current.name === 'unavailable') {
                    $state.go($rootScope.requestedState, $rootScope.requestedStateParams);
                  } else {
                    $rootScope.requestedState = null;
                    $rootScope.requestedStateParams = null;
                    $urlRouter.sync();
                  }
                } else {
                  $rootScope.requestedState = null;
                  $rootScope.requestedStateParams = null;
                  $log.debug('Resume session ended in unknown state', data);
                }
              })
              .catch(function () {
                if (!next?.data || !next?.data?.authorizedRoles) {
                  $rootScope.requestedState = null;
                  $rootScope.requestedStateParams = null;
                  $urlRouter.sync();
                } else {
                  $log.debug('Session resume attempt failed');
                  var userRoles = [];
                  if (!AuthService.isAuthorized(userRoles)) {
                    $rootScope.$broadcast(AUTH_EVENTS.notAuthenticated);
                  }
                }
              })
              .finally(function () {
                AuthService.removeLoading();
              });
          } else {
            Session.destroy();
            $urlRouter.sync();
          }
        }
      } else {
        $log.debug('Attempting to access state: ' + next.name, next);

        if (
          [
            'login',
            'tech-channels',
            'login-catchroot',
            'login-catchslash',
            'register',
            'registerToken',
            'forgot-password',
            'reset-password',
            'user-registration',
          ].indexOf(next.name) > -1
        ) {
          if (AuthService.isAuthenticated() && !Session.isAnonymous()) {
            event.preventDefault();
            defaultRedirects($state, AuthService, Session, USER_ROLES);
            return;
          }
        }

        if (next.data && next.data.authorizedRoles) {
          if (!AuthService.isAuthorized(next.data.authorizedRoles)) {
            event.preventDefault();
            $log.debug('Authorized roles for ' + next.name + ' are: ', next.data.authorizedRoles);
            if (AuthService.isAuthenticated()) {
              // user is not allowed
              $log.debug('User not authorized: auth');
              $translate(['OK', 'ERROR', 'NOT_AUTHORIZED']).then(function (translations) {
                swal(
                  {
                    title: translations.ERROR,
                    text: translations.NOT_AUTHORIZED,
                    type: 'error',
                    confirmButtonText: translations.OK,
                  },
                  function () {
                    defaultRedirects($state, AuthService, Session, USER_ROLES);
                  }
                );
              });
            } else {
              // user is not logged in
              $rootScope.requestedState = next.name;
              $rootScope.requestedStateParams = nextParams;

              // fiddle out the path for react router states so that it follows the rest of the login flow (i.e. saving
              // to localstorage for SSO) and we can reconstruct it at the end of the login flow when we do the redirect
              if (Object.values(react_router_states).includes(next.name)) {
                $rootScope.requestedStateParams = {
                  path: $location.url().substr($location.url().indexOf(next.name) + next.name.length),
                };
              }

              $log.debug('User not authenticated');
              $state.go('login');
            }
          } else {
            if (next.name === 'challenge-test') {
              //
            } else if (
              AuthService.isAuthorized([
                USER_ROLES.admin,
                USER_ROLES.reseller,
                USER_ROLES.companyAdmin,
                USER_ROLES.manager,
              ]) &&
              !Session.user.properties.profile.isComplete &&
              next.name !== 'profile'
            ) {
              //
            } else {
              $rootScope.gameId = Session.user.properties.gameLastPlayed;
            }

            //specifically added for LP feature
            if (next.data.feature) {
              if (!AuthService.isFeatureEnabled(next.data.feature)) {
                event.preventDefault();
                $log.debug('User not authorized: auth');
                $translate(['OK', 'ERROR', 'NOT_AUTHORIZED']).then(function (translations) {
                  swal(
                    {
                      title: translations.ERROR,
                      text: translations.NOT_AUTHORIZED,
                      type: 'error',
                      confirmButtonText: translations.OK,
                    },
                    function () {
                      defaultRedirects($state, AuthService, Session, USER_ROLES);
                    }
                  );
                });
                return;
              }
            }

            $log.debug('Access to state granted: ', next.name);
            if (!Session.user.properties.profile.isComplete && $rootScope.requestedState) {
              const redirectOnProfileCompletion = {
                requestedState: $rootScope.requestedState,
                requestedStateParams: $rootScope.requestedStateParams,
              };
              $window.localStorage.setItem('redirectOnProfileCompletion', JSON.stringify(redirectOnProfileCompletion));
            }
            $rootScope.requestedState = null;
            $rootScope.requestedStateParams = null;
          }
        } else {
          $log.debug('State does not require auth: ', next.name);
        }
      }
    });

    // catch state resolve failure
    $rootScope.$on('$stateChangeError', function (_event, toState, toParams, fromState, fromParams, error) {
      const message = `Target state name ${toState.name} fromState ${fromState} with params ${fromParams} toState ${toState} with params ${toParams}. Error:${error}`;
      $log.error('$stateChangeError', message);
      $window.location.reload();
    });

    const goToLogin = function (_response) {
      Session.destroy();

      $log.debug('Received "Not Authenticated" event');
      $state.go('login');
      $translate(['SESSION_TIMED_OUT']).then(function (translations) {
        $rootScope.loginFlashMesage = translations.SESSION_TIMED_OUT;
      });
    };

    $rootScope.$on(AUTH_EVENTS.loginFailed, function () {
      AnalyticsService.logEvent(AnalyticsEvents.General.LOGIN, {
        login_type: $state.current.name,
        login_method: $state.current.name === 'sso-login' ? 'sso' : 'standard',
        login_destination: $rootScope.requestedState || 'default-redirect-state',
        login_result: 'failed',
      });
      goToLogin();
    });

    $rootScope.$on(AUTH_EVENTS.notAuthenticated, function () {
      const eventProps = AnalyticsService.getDefaultEventProps();

      AnalyticsService.logEvent(AnalyticsEvents.General.SESSION_TIMED_OUT, {
        url: eventProps.url,
        host: eventProps.host,
        path: eventProps.path,
        page_name: $rootScope.requestedState || 'default-redirect-state',
      });
      goToLogin();
    });

    $rootScope.ErrorHandler = ErrorHandler;
    $rootScope.Session = Session;
    $rootScope.userRoles = USER_ROLES;
    $rootScope.isAuthorized = AuthService.isAuthorized;
    $rootScope.isAuthenticated = AuthService.isAuthenticated;
    $rootScope.hasCookiePolicy = AuthService.hasCookiePolicy;
    $rootScope.isFeatureEnabled = AuthService.isFeatureEnabled;
    $rootScope.isFeatureUnlimited = AuthService.isFeatureUnlimited;
    $rootScope.isStandaloneDeveloper = AuthService.isStandaloneDeveloper;
    $rootScope.isStandaloneTeam = AuthService.isStandaloneTeam;
    $rootScope.isSSOUser = AuthService.isSSOUser;
    $rootScope.isStrictSso = AuthService.isStrictSso;
    $rootScope.hasTournamentAccess = AuthService.hasTournamentAccess;
    $rootScope.hasDevlympicsAccess = AuthService.hasDevlympicsAccess;
    $rootScope.requestedState = null;
    $rootScope.requestedStateParams = null;
    $rootScope.loading = 0;
    $rootScope.defaultRedirects = function () {
      defaultRedirects($state, AuthService, Session, USER_ROLES);
    };

    const SHORTHAND_ROLES = {
      'sa ': 'scwadmin',
      'ca ': 'companyadmin',
      'tm ': 'teammanager',
      'd ': 'developer',
    };

    $rootScope.expandShorthandEmail = function (email) {
      var matchedShorthandRole = null;

      if (!$window.emailRegex.test(email)) {
        for (let shorthandRole in SHORTHAND_ROLES) {
          if (email.indexOf(shorthandRole) === 0) {
            matchedShorthandRole = shorthandRole;
            break;
          }
        }

        if (matchedShorthandRole) {
          email =
            SHORTHAND_ROLES[matchedShorthandRole] +
            '+' +
            email.slice(matchedShorthandRole.length) +
            '@securecodewarrior.com';
        }
      }

      return email;
    };

    $rootScope.impersonateEmail = {};

    $rootScope.impersonateEmailOnChange = function (event) {
      $rootScope.impersonateEmail.address = event.target.value;
    };

    $rootScope.impersonateUserOnClick = function () {
      $rootScope.impersonateUser($rootScope.impersonateEmail.address);
    };

    $rootScope.impersonateUser = function (impersonateEmail) {
      if (!impersonateEmail) {
        swal('Error', 'Invalid email address', 'error');
        return;
      }

      // if shorthand email is given, expand it before running other logic
      impersonateEmail = $rootScope.expandShorthandEmail(impersonateEmail);

      $rootScope.disableButton('impersonateUser');
      AdminApiService.addLoading();
      AdminApiService.impersonateUser(impersonateEmail)
        .then(function (data) {
          const { impersonated, sessionId } = data;
          $log.debug('Impersonating: ', impersonated);
          if (
            impersonated.status === $rootScope.metadata.constants.user.status.ENABLED ||
            impersonated.status === $rootScope.metadata.constants.user.status.REGISTERED
          ) {
            Session.create(sessionId, impersonated);
            $rootScope.defaultRedirects();
          } else {
            swal('Error', 'User is not active. You may need to log out and log back in to continue.', 'error');
          }
        })
        .catch(function (response) {
          $translate([
            'Failed_TO_IMPERSONATE',
            'FAILED_TO_IMPERSONATE_USER',
            'DOES_NOT_CONFIRM_TO_THE_EMAIL_FORMAT',
          ]).then(function (translations) {
            if (response.data.error === 'INVALID_USER_EMAIL') {
              ErrorHandler.addError(translations.Failed_TO_IMPERSONATE);
            } else if (response.data.details.validationErrors[0].message === 'does not conform to the "email" format') {
              ErrorHandler.addHttpError(translations.DOES_NOT_CONFIRM_TO_THE_EMAIL_FORMAT);
            } else {
              ErrorHandler.addHttpError(translations.FAILED_TO_IMPERSONATE_USER, response);
            }
          });
        })
        .finally(function () {
          AdminApiService.removeLoading();
          $rootScope.enableButton('impersonateUser');
        });
    };

    $rootScope.resendActivation = function () {
      const sendMe = {
        email: Session.user.email,
      };

      $rootScope.disableButton('resetDemoUserState');
      AuthService.addLoading();
      $translate([
        'OK',
        'ACTIVATION_EMAIL_SENT',
        'ACTIVATION_EMAIL_LINK_SENT_BY_EMAIL',
        'FAILED_TO_RESEND_ACTIVATION_EMAIL',
      ]).then(function (translations) {
        AuthService.resendActivation(sendMe)
          .then(function (_data) {
            swal({
              title: translations.ACTIVATION_EMAIL_SENT,
              text: translations.ACTIVATION_EMAIL_LINK_SENT_BY_EMAIL,
              type: 'success',
              confirmButtonText: translations.OK,
            });
          })
          .catch(function (response) {
            ErrorHandler.addHttpError(translations.FAILED_TO_RESEND_ACTIVATION_EMAIL, response);
          })
          .finally(function () {
            AuthService.removeLoading();
            $rootScope.enableButton('resetDemoUserState');
          });
      });
    };
  },
]);

/*****************************************************************/

// for intercepting HTTP responses and broadcasting events, also for retrying HTTP requests
app.factory('AuthInterceptor', [
  '$rootScope',
  '$q',
  '$log',
  '$translate',
  'AUTH_EVENTS',
  '$window',
  '$stateParams',
  '$injector',
  /*					 */
  function ($rootScope, $q, $log, $translate, AUTH_EVENTS, $window, $stateParams, $injector) {
    function retryRequest(httpConfig) {
      const DELAY_MS = 10000;
      const $timeout = $injector.get('$timeout');
      const $http = $injector.get('$http');

      $rootScope.currentLoadingMessage = $translate.instant('RETRYING_CONNECTION');

      $log.debug('Retrying $http request to ' + httpConfig.url + ' in ' + DELAY_MS + 'ms');
      return $timeout(function () {}, DELAY_MS)
        .then(function () {
          $log.debug('1st retry of $http request to ' + httpConfig.url);
          httpConfig.isRetry = true;
          return $http(httpConfig);
        })
        .catch(function (response) {
          if (response.status === 502 || response.status === 503 || response.status === -1 || response.status === 0) {
            // server unreachable again so don't throw, retry again
          } else {
            // some other error
            throw response;
          }
        })
        .then(function () {
          $log.debug('Retrying $http request to ' + httpConfig.url + ' in ' + 6 * DELAY_MS + 'ms');
          return $timeout(function () {}, 6 * DELAY_MS);
        })
        .then(function () {
          $log.debug('2nd retry of $http request to ' + httpConfig.url);
          httpConfig.isRetry = true;
          httpConfig.is2ndRetry = true;
          return $http(httpConfig);
        });
    }

    return {
      responseError: function (response) {
        // for detecting auth status errors
        if ([401].indexOf(response.status) >= 0 && !response.config.isEndpointUnawareCheck) {
          $rootScope.$broadcast(
            {
              401: AUTH_EVENTS.notAuthenticated,
            }[response.status],
            response
          );
        } else if ([403].indexOf(response.status) >= 0) {
          $rootScope.$broadcast(
            {
              403: AUTH_EVENTS.notAuthorized,
            }[response.status],
            response
          );

          return $q.reject(response);
        } else if (response.status === 400 || response.status === 404 || response.status === 405) {
          return $q.reject(response);
        }
        // for detecting endpoint down and request cancelled
        else if (
          response.status === 502 ||
          response.status === 503 ||
          response.status === -1 ||
          response.status === 0
        ) {
          const $state = $injector.get('$state');
          if (
            response.config &&
            response.config.timeout &&
            response.config.timeout.$$state &&
            response.config.timeout.$$state.value === 'Abort $http'
          ) {
            // request cancelled by us
            response.data = {};
            response.data.error = 'HTTP request aborted';
            return $q.reject(response);
          } else if (response.config.url.indexOf('freegeoip.net') > -1) {
            // ignore free geo IP request
            return $q.reject(response);
          } else if (response.config.url === $window.SCW_ENV.ApiEndpoint + '/ping') {
            // do nothing for /ping request
            return $q.reject(response);
          } else {
            // 503 on CORS pre-flight OPTIONS request also comes here. the actual request gets cancelled by the browser so we never see the 503
            if (response.config.isRetry) {
              // don't retry a retry attempt
              return $q.reject(response);
            } else {
              return retryRequest(response.config).catch(function (response) {
                return $translate(['CONNECTION_ERROR']).then(function (translations) {
                  if ($state.current.name !== '' && $state.current.name !== 'unavailable') {
                    $rootScope.requestedState = $state.current.name;
                    $rootScope.requestedStateParams = JSON.parse(JSON.stringify($stateParams));
                    $state.go('unavailable');
                  }

                  response.data = {};
                  response.data.error = translations.CONNECTION_ERROR;

                  throw response;
                });
              });
            }
          }
        } else {
          // hacky way of reporting http error messages to the app owner
          return $q.reject(response);
        }
      },
    };
  },
]);

/*****************************************************************/
app.controller('RegisterController', [
  '$log',
  '$scope',
  '$window',
  '$rootScope',
  'AuthService',
  '$state',
  '$stateParams',
  '$translate',
  'ErrorHandler',
  'AnalyticsService',
  'AnalyticsEvents',
  function (
    $log,
    $scope,
    $window,
    $rootScope,
    AuthService,
    $state,
    $stateParams,
    $translate,
    ErrorHandler,
    AnalyticsService,
    AnalyticsEvents
  ) {
    $scope.credentials = {
      email: '',
      pass: '',
    };
    $scope.confirmPass = '';
    $scope.expanded = false;

    $scope.updateExpanded = function () {
      $scope.expanded = !$scope.expanded;
    };

    const senseiAuthToken = AuthService.getSenseiAuthToken();
    if (senseiAuthToken) {
      $scope.token = $window.SCW_ENV.SENSEI_TRIAL_COMPANY_REGISTRATION_TOKEN;
      $scope.tokenGiven = true;
    }

    //disable token input field if token given in url
    if ($stateParams.token) {
      $scope.token = $stateParams.token;
      $scope.tokenGiven = true;
    }

    // used for matching password and confirm password - fixes edge case of confirm password entered before password
    $scope.$watch(
      function (scope) {
        return scope.credentials.pass;
      },
      function (_newval, _oldval) {
        $scope.registerForm.confirmPassword.$setValidity('match', $scope.credentials.pass === $scope.confirmPass);
      }
    );

    $scope.register = function (credentials) {
      if ($scope.registerForm.$invalid || !credentials.email.match($window.emailRegex)) {
        if (!credentials.email.match($window.emailRegex)) {
          $scope.registerForm.email.$invalid = true;
          $scope.registerForm.email.$valid = false;
          $scope.registerForm.email.$error = $scope.registerForm.email.$error || {};
          $scope.registerForm.email.$error.email = true;
        }
        $scope.registerForm.email.$dirty = true;
        $scope.registerForm.password.$dirty = true;
        $scope.registerForm.confirmPassword.$dirty = true;
        $scope.registerForm.token.$dirty = true;
        $rootScope.assignFocusToFirstAngularJSFormInputWithAnError($scope.registerForm);

        return;
      }

      delete credentials.token;
      $scope.token = $scope.token.replace(/ /g, '');

      $rootScope.disableButton('register');
      AuthService.addLoading();
      $translate([
        'PROFILE_LANGUAGE_PREFERENCE_UPDATE_SUCCESSFUL',
        'UNABLE_TO_UPDATE_PROFILE_LANGUAGE_PREFERENCE',
        'INVALID_INVITATION_TOKEN_PROVIDED_PLEASE_CHECK_YOU_HAVE_ENTERED_IT_CORRECTLY',
        'PLEASE_CHOOSE_A_STRONGER_PASSWORD',
        'REGISTRATION_ERROR',
        'DOES_NOT_MEET_MINIMUM_LENGTH_OF_9',
      ]).then(function (translations) {
        $scope.language = { i18nLanguagePreference: $translate.use() };
        AuthService.register(credentials, $scope.token)
          .then(function (data) {
            $log.debug('called register() ', data);

            if (data === 'SSO_NO_ACTION') {
              return;
            } // early return to prevent $state.go from cancelling the redirect to SSO login page, or to stay on the register page if there is an SSO error

            $scope.registerSuccess = true;
            AnalyticsService.logEvent(AnalyticsEvents.Onboarding.INVITE_USER, {
              provision_type: 'self register',
              user_register_status: 'yes',
            });

            // lang stuff
            AuthService.addLoading();
            AuthService.updateProfileLanguage($scope.language)
              .then(function (data) {
                $log.debug(translations.PROFILE_LANGUAGE_PREFERENCE_UPDATE_SUCCESSFUL, data);
                $rootScope.changeLanguage($scope.language.i18nLanguagePreference);

                if ($scope.token === $window.SCW_ENV.SENSEI_TRIAL_COMPANY_REGISTRATION_TOKEN) {
                  $state.go('sensei-auth');
                  return;
                }

                $state.go('home');
              })
              .catch(function (response) {
                AnalyticsService.logEvent(AnalyticsEvents.Onboarding.INVITE_USER, {
                  provision_type: 'self register',
                  user_register_status: 'no',
                });
                ErrorHandler.addHttpError(translations.UNABLE_TO_UPDATE_PROFILE_LANGUAGE_PREFERENCE, response);
              });
          })
          .catch(function (response) {
            AnalyticsService.logEvent(AnalyticsEvents.Onboarding.INVITE_USER, {
              provision_type: 'self register',
              user_register_status: 'no',
            });
            $translate(['EMAIL_ADDRESS_HAS_ALREADY_BEEN_REGISTERED', 'NO_MORE_LICENSE_CONTACT_YOUR_TEAM_MANAGER']).then(
              function (translations) {
                if (response.message && response.message === 'User account is not active') {
                  ErrorHandler.addError(translations.EMAIL_ADDRESS_HAS_ALREADY_BEEN_REGISTERED);
                } else if (
                  response.data &&
                  response.data.error ===
                    'There are no more licenses available in your team. Contact your team manager.'
                ) {
                  ErrorHandler.addError(translations.NO_MORE_LICENSE_CONTACT_YOUR_TEAM_MANAGER);
                }
              }
            );
            if (
              response.data &&
              response.data.error === 'Invalid request parameters' &&
              response.data.details &&
              response.data.details.validationErrors &&
              response.data.details.validationErrors[0]
            ) {
              if (
                response.data.details.validationErrors[0].stack &&
                response.data.details.validationErrors[0].stack.match('request.body.token is not one of enum values')
              ) {
                ErrorHandler.addError(
                  translations.INVALID_INVITATION_TOKEN_PROVIDED_PLEASE_CHECK_YOU_HAVE_ENTERED_IT_CORRECTLY
                );
              } else if (
                response.data.details.validationErrors[0].stack &&
                response.data.details.validationErrors[0].stack.match('request.body.pass is a weak password')
              ) {
                ErrorHandler.addError(translations.PLEASE_CHOOSE_A_STRONGER_PASSWORD);
                $scope.registerForm.password.$valid = false;
                $scope.registerForm.password.$invalid = true;
                $scope.registerForm.confirmPassword.$valid = false;
                $scope.registerForm.confirmPassword.$invalid = true;
              } else if (
                response.data.details.validationErrors[0].stack &&
                response.data.details.validationErrors[0].stack.match(
                  'request.body.token does not meet minimum length of 9'
                )
              ) {
                response.data.details.validationErrors[0].message = translations.DOES_NOT_MEET_MINIMUM_LENGTH_OF_9;
                ErrorHandler.addHttpError(translations.REGISTRATION_ERROR, response);
              } else {
                ErrorHandler.addHttpError(translations.REGISTRATION_ERROR, response);
              }
            } else if (
              response &&
              response.data &&
              response.data.error &&
              (response.data.error + '').startsWith('Insufficient password strength')
            ) {
              // same as above but split out into another else-if for clarity
              ErrorHandler.addError(response.data.error);
              $scope.registerForm.password.$valid = false;
              $scope.registerForm.password.$invalid = true;
              $scope.registerForm.confirmPassword.$valid = false;
              $scope.registerForm.confirmPassword.$invalid = true;
            } else if (
              response &&
              response.usResponse &&
              response.usResponse.data &&
              response.usResponse.data.error &&
              ((response.usResponse.data.error + '').startsWith('Insufficient password strength') ||
                response.usResponse.data.error === 'Email address has already been registered')
            ) {
              // same as above but split out into another else-if for clarity
              // dealing with failed password strength check on US server followed by invalid invitation/registration token on EU server
              // there are essentially two major errors we are looking for in US, password strength and invalid email address.
              ErrorHandler.addError(response.usResponse.data.error);
              $scope.registerForm.password.$valid = false;
              $scope.registerForm.password.$invalid = true;
              $scope.registerForm.confirmPassword.$valid = false;
              $scope.registerForm.confirmPassword.$invalid = true;
            } else if (response.data && response.data.error === 'Invalid registration token') {
              ErrorHandler.addError(
                translations.INVALID_INVITATION_TOKEN_PROVIDED_PLEASE_CHECK_YOU_HAVE_ENTERED_IT_CORRECTLY
              );
            } else if (response.data && response.data.error === 'Email address has already been registered') {
              ErrorHandler.addError(response.data.error);
            } else {
              ErrorHandler.addHttpError(translations.REGISTRATION_ERROR, response);
            }
          })
          .finally(function () {
            AuthService.removeLoading();
            $rootScope.enableButton('register');
          });
      });
    };
  },
]);

app.controller('InviteAcceptController', [
  '$log',
  '$scope',
  '$rootScope',
  'AuthService',
  'USER_ROLES',
  '$state',
  '$stateParams',
  '$translate',
  'ErrorHandler',
  'AnalyticsService',
  'AnalyticsEvents',
  function (
    $log,
    $scope,
    $rootScope,
    AuthService,
    USER_ROLES,
    $state,
    $stateParams,
    $translate,
    ErrorHandler,
    AnalyticsService,
    AnalyticsEvents
  ) {
    $scope.credentials = { pass: '' };
    $scope.confirmPass = '';
    $scope.success = false;
    $scope.failed = false;
    $scope.email = $stateParams.email || '';

    $scope.init = function () {
      $scope.language = { i18nLanguagePreference: $translate.use() };

      if (!$scope.email) {
        AuthService.getEmailByActivationToken($stateParams.token)
          .then(function (data) {
            $scope.email = data.email;
            $rootScope.changeLanguage(data.properties.profile.i18nLanguagePreference);

            AnalyticsService.logEvent(AnalyticsEvents.Onboarding.INVITE_EMAIL_CLICKED);
          })
          .catch(function (e) {
            if (e.data?.error) {
              ErrorHandler.addError(e.data.error);
            }
          });
      }
    };

    // used for matching password and confirm password - fixes edge case of confirm password entered before password
    $scope.$watch(
      function (scope) {
        return scope.credentials.pass;
      },
      function (_newval, _oldval) {
        $scope.inviteAcceptForm.confirmPassword.$setValidity('match', $scope.credentials.pass === $scope.confirmPass);
      }
    );

    $scope.editPassword = function () {
      $scope.inviteAcceptForm.password.$setValidity('weakPassword', true);
      $scope.inviteAcceptForm.confirmPassword.$setValidity('weakPassword', true);
    };

    $scope.acceptClick = function () {
      if ($scope.inviteAcceptForm.$invalid) {
        $scope.inviteAcceptForm.password.$dirty = true;
        $scope.inviteAcceptForm.confirmPassword.$dirty = true;
        $rootScope.assignFocusToFirstAngularJSFormInputWithAnError($scope.inviteAcceptForm);

        return;
      }

      $scope.acceptance = {};
      $scope.acceptance.token = $stateParams.token;
      $scope.acceptance.pass = $scope.credentials.pass;
      $rootScope.disableButton('inviteAccept');
      AuthService.addLoading();
      $scope.language = { i18nLanguagePreference: $translate.use() };

      AuthService.inviteAccept($scope.acceptance)
        .then(function (data) {
          $log.debug('Invitation acceptance successful ', data);
          if (data) {
            $scope.success = true;
            $translate([
              'PROFILE_LANGUAGE_PREFERENCE_UPDATE_SUCCESSFUL',
              'UNABLE_TO_UPDATE_PROFILE_LANGUAGE_PREFERENCE',
            ]).then(function (translations) {
              // lang stuff
              AuthService.addLoading();
              AuthService.updateProfileLanguage($scope.language)
                .then(function (data) {
                  $log.debug(translations.PROFILE_LANGUAGE_PREFERENCE_UPDATE_SUCCESSFUL, data);
                  if ($stateParams.assessment && $stateParams.attempt) {
                    $rootScope.assessmentProfileIncomplete = {
                      assessmentId: $stateParams.assessment,
                      attemptId: $stateParams.attempt,
                    };
                  }
                  $rootScope.changeLanguage($scope.language.i18nLanguagePreference);
                  //The profile will be incomplete for the SCW admin. For the rest go to the home page
                  if (AuthService.isAuthorized(USER_ROLES.admin)) {
                    //This will fix the issue with accepting invites
                    $state.go('profile'); //redirect to the profile page for the SCW admin
                  } else {
                    $state.go('home');
                  }
                })
                .catch(function (response) {
                  ErrorHandler.addHttpError(translations.UNABLE_TO_UPDATE_PROFILE_LANGUAGE_PREFERENCE, response);
                });
            });
          }
        })
        .catch(function (response) {
          $scope.failed = true;
          $translate([
            'PLEASE_CHOOSE_A_STRONGER_PASSWORD',
            'UNABLE_TO_ACCEPT_INVITATION',
            'PROVIDED_USER_TOKEN_PAIR_INVALID_OR_ACCOUNT_ALREADY_ACTIVE',
          ]).then(function (translations) {
            const passwordErrorResponse = _.get(response, 'data.error');
            if (
              (_.get(response, 'data.error') === 'Invalid request parameters' &&
                _.get(response, 'data.details.validationErrors[0].message') === 'is a weak password') ||
              (passwordErrorResponse + '').startsWith('Insufficient password strength')
            ) {
              ErrorHandler.addError(
                passwordErrorResponse && passwordErrorResponse.length > 0
                  ? passwordErrorResponse
                  : translations.PLEASE_CHOOSE_A_STRONGER_PASSWORD
              );
              $scope.inviteAcceptForm.password.$valid = false;
              $scope.inviteAcceptForm.password.$invalid = true;
              $scope.inviteAcceptForm.confirmPassword.$valid = false;
              $scope.inviteAcceptForm.confirmPassword.$invalid = true;
              $scope.inviteAcceptForm.password.$setValidity('weakPassword', false);
              $scope.inviteAcceptForm.confirmPassword.$setValidity('weakPassword', false);
            } else if (
              _.get(response, 'data.error') === 'Provided user/token pair is invalid or the account is already active'
            ) {
              ErrorHandler.addError(translations.PROVIDED_USER_TOKEN_PAIR_INVALID_OR_ACCOUNT_ALREADY_ACTIVE);
              $scope.inviteAcceptForm.password.$valid = false;
              $scope.inviteAcceptForm.password.$invalid = true;
              $scope.inviteAcceptForm.confirmPassword.$valid = false;
              $scope.inviteAcceptForm.confirmPassword.$invalid = true;
            } else {
              ErrorHandler.addHttpError(translations.UNABLE_TO_ACCEPT_INVITATION, response);
            }
          });
        })
        .finally(function () {
          AuthService.removeLoading();
          $rootScope.enableButton('inviteAccept');
        });
    };
    $scope.init();
  },
]);

app.controller('ForgotPasswordController', [
  '$log',
  '$scope',
  '$rootScope',
  'AuthService',
  '$translate',
  'ErrorHandler',
  function ($log, $scope, $rootScope, AuthService, $translate, ErrorHandler) {
    $scope.resetPasswordCompleted = false;
    if ($rootScope.receivedEmail) {
      resetPassword($rootScope.receivedEmail);
      $rootScope.receivedEmail = null;
    } else {
      $scope.resetPasswordCompleted = true;
    }

    $scope.email = '';

    $scope.forgotPassword = function (email) {
      if ($scope.resetForm.$invalid) {
        $scope.resetForm.email.$dirty = true;

        $rootScope.assignFocusToFirstAngularJSFormInputWithAnError($scope.resetForm);
        return;
      }
      resetPassword(email);
    };

    function resetPassword(email) {
      $rootScope.disableButton('forgotPassword');
      AuthService.addLoading();
      $translate(['SSO_IMPROPERLY_CONFIGURED_FOR_YOUR_COMPANY_CONTACT_YOUR_ADMIN', 'PASSWORD_RESET_ERROR']).then(
        function (translations) {
          AuthService.checkAuthContext(email).then(function (data) {
            if (data && data.scw_configured) {
              // go to SAML redirection stuff
              if (!data.login_url)
                return ErrorHandler.addHttpError(
                  translations.SSO_IMPROPERLY_CONFIGURED_FOR_YOUR_COMPANY_CONTACT_YOUR_ADMIN
                );
              window.location.href = data.login_url;
            } else {
              AuthService.forgotPassword(email)
                .then(function (data) {
                  $log.debug('called forgotPassword() ', data);
                  $scope.resetDone = true;
                  $scope.resetPasswordCompleted = true;
                })
                .catch(function (response) {
                  ErrorHandler.addHttpError(translations.PASSWORD_RESET_ERROR, response);
                })
                .finally(function () {
                  AuthService.removeLoading();
                  $rootScope.enableButton('forgotPassword');
                });
            }
          });
        }
      );
    }
  },
]);

app.controller('ActivateController', [
  '$log',
  '$scope',
  'AuthService',
  'Session',
  'USER_ROLES',
  '$state',
  '$stateParams',
  '$translate',
  'ErrorHandler',
  function ($log, $scope, AuthService, Session, USER_ROLES, $state, $stateParams, $translate, ErrorHandler) {
    $scope.activation = {};
    $scope.activation.token = $stateParams.token;
    $scope.success = false;
    $scope.failed = false;

    AuthService.addLoading();
    AuthService.activate($scope.activation)
      .then(function (data) {
        $log.debug('Activation successful ', data);
        if (data) {
          $scope.success = true;
          defaultRedirects($state, AuthService, Session, USER_ROLES);
        }
      })
      .catch(function (response) {
        $scope.failed = true;
        $translate(['ACCOUNT_ACTIVATION_ERROR']).then(function (translations) {
          ErrorHandler.addHttpError(translations.ACCOUNT_ACTIVATION_ERROR, response);
        });
      })
      .finally(function () {
        AuthService.removeLoading();
      });
  },
]);

app.controller('PublicTournamentRegistrationController', [
  '$log',
  '$scope',
  '$rootScope',
  '$translate',
  'AuthService',
  'ErrorHandler',
  function ($log, $scope, $rootScope, $translate, AuthService, ErrorHandler) {
    $scope.email = '';
    $scope.registrationDone = false;

    $scope.registerEmail = function (email) {
      if ($scope.publicRegistrationForm.$invalid) {
        $scope.publicRegistrationForm.email.$dirty = true;
        $rootScope.assignFocusToFirstAngularJSFormInputWithAnError($scope.publicRegistrationForm);
        return;
      }
      registerForPublicTournament(email);
    };

    function registerForPublicTournament(email) {
      $rootScope.disableButton('publicRegistration');
      AuthService.addLoading();
      AuthService.publicTournamentRegistration(email)
        .then(function (data) {
          $log.debug('called public registration() ', data);
          $scope.registrationDone = true;
        })
        .catch(function (response) {
          $translate(['PASSWORD_RESET_ERROR']).then(function (translations) {
            ErrorHandler.addHttpError(translations.PASSWORD_RESET_ERROR, response);
          });
        })
        .finally(function () {
          AuthService.removeLoading();
          $rootScope.enableButton('publicRegistration');
        });
    }
  },
]);

app.controller('ProductTrialActivateController', [
  '$log',
  '$scope',
  'AuthService',
  'Session',
  'USER_ROLES',
  '$state',
  '$stateParams',
  '$translate',
  'ErrorHandler',
  function ($log, $scope, AuthService, Session, USER_ROLES, $state, $stateParams, $translate, ErrorHandler) {
    const token = $stateParams.token;
    $scope.success = false;
    $scope.failed = false;

    AuthService.addLoading();
    AuthService.productTrialActivate(token)
      .then(function (data) {
        $log.debug('productTrialActivate successful ', data);
        if (data) {
          $scope.success = true;
          defaultRedirects($state, AuthService, Session, USER_ROLES);
        }
      })
      .catch(function (response) {
        $scope.failed = true;
        $translate(['ACCOUNT_ACTIVATION_ERROR']).then(function (translations) {
          ErrorHandler.addHttpError(translations.ACCOUNT_ACTIVATION_ERROR, response);
        });
      })
      .finally(function () {
        AuthService.removeLoading();
      });
  },
]);

app.controller('ResetPasswordController', [
  '$log',
  '$scope',
  '$rootScope',
  'AuthService',
  '$stateParams',
  '$translate',
  'ErrorHandler',
  function ($log, $scope, $rootScope, AuthService, $stateParams, $translate, ErrorHandler) {
    $scope.resetReq = {};
    $scope.resetReq.token = $stateParams.token;
    $scope.confirmPass = '';
    $scope.resetSuccess = false;

    // used for matching password and confirm password - fixes edge case of confirm password entered before password
    $scope.$watch(
      function (scope) {
        return scope.resetReq.pass;
      },
      function (_newval, _oldval) {
        $scope.resetForm.confirmPassword.$setValidity('match', $scope.resetReq.pass === $scope.confirmPass);
      }
    );

    $scope.resetPassword = function (resetReq) {
      if ($scope.resetForm.$invalid) {
        $scope.resetForm.password.$dirty = true;
        $scope.resetForm.confirmPassword.$dirty = true;
        $rootScope.assignFocusToFirstAngularJSFormInputWithAnError($scope.resetForm);

        return;
      }

      $rootScope.disableButton('resetPassword');
      AuthService.addLoading();
      $translate(['PLEASE_CHOOSE_A_STRONGER_PASSWORD', 'PASSWORD_RESET_ERROR']).then(function (translations) {
        AuthService.resetPassword(resetReq)
          .then(function (data) {
            $log.debug('called resetPassword() ', data);
            $scope.resetSuccess = true;
          })
          .catch(function (response) {
            if (
              response.data.error === 'Invalid request parameters' &&
              response.data.details &&
              response.data.details.validationErrors &&
              response.data.details.validationErrors[0] &&
              response.data.details.validationErrors[0].stack &&
              response.data.details.validationErrors[0].stack.match('request.body.pass is a weak password')
            ) {
              ErrorHandler.addError(translations.PLEASE_CHOOSE_A_STRONGER_PASSWORD);
              $scope.resetForm.password.$valid = false;
              $scope.resetForm.password.$invalid = true;
              $scope.resetForm.confirmPassword.$valid = false;
              $scope.resetForm.confirmPassword.$invalid = true;
            } else if (
              response &&
              response.data &&
              response.data.error &&
              (response.data.error + '').startsWith('Insufficient password strength')
            ) {
              // same as above but split out into another else-if for clarity
              ErrorHandler.addError(response.data.error);
              $scope.resetForm.password.$valid = false;
              $scope.resetForm.password.$invalid = true;
              $scope.resetForm.confirmPassword.$valid = false;
              $scope.resetForm.confirmPassword.$invalid = true;
            } else if (
              response &&
              response.usResponse &&
              response.usResponse.data &&
              response.usResponse.data.error &&
              (response.usResponse.data.error + '').startsWith('Insufficient password strength')
            ) {
              // same as above but split out into another else-if for clarity
              // dealing with failed password strength check on US server followed by invalid reset token on EU server - PORTAL-2594
              ErrorHandler.addError(response.usResponse.data.error);
              $scope.resetForm.password.$valid = false;
              $scope.resetForm.password.$invalid = true;
              $scope.resetForm.confirmPassword.$valid = false;
              $scope.resetForm.confirmPassword.$invalid = true;
            } else {
              ErrorHandler.addHttpError(translations.PASSWORD_RESET_ERROR, response);
            }
          })
          .finally(function () {
            AuthService.removeLoading();
            $rootScope.enableButton('resetPassword');
          });
      });
    };
  },
]);

app.controller('LogoutController', [
  'AuthService',
  'AnalyticsService',
  'AnalyticsEvents',
  'UserflowService',
  function (AuthService, AnalyticsService, AnalyticsEvents, UserflowService) {
    AnalyticsService.logEvent(AnalyticsEvents.General.LOGOUT, {}).finally(AuthService.logout);
    UserflowService.reset();
  },
]);
