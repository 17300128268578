import { styled } from '@mui/material';
import { Card } from '@securecodewarrior/design-system-react/lib/labs';
export const CybermonAchievementCard = styled(Card)(({ theme }) => ({
    display: 'grid',
    gridTemplateColumns: '80px 1fr',
    minHeight: '80px',
    height: '100%',
    borderRadius: theme.shape.borderRadius * 3,
    alignItems: 'center',
    alignContent: 'center',
    padding: 0,
    flexGrow: 1,
    flexBasis: 268,
}));
