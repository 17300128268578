import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
import { Box, Dialog, Stack, Typography, useTheme } from '@mui/material';
import { Button } from '@securecodewarrior/design-system-react/lib/wanda';
import { GoalEditorSection, GoalEditorSubsection } from './GoalEditorSection';
import AddIcon from '@mui/icons-material/Add';
import EditIcon from '@mui/icons-material/Edit';
import InfoIcon from '@mui/icons-material/Info';
import { useMemo, useState } from 'react';
import { Trans, useTranslation } from 'react-i18next';
import { usePlatformContext } from '../../../../platformContext';
import { goalDetailsAnalytics } from '../../../analytics/GoalDetails.analytics';
import { TeamAssignment } from '../TeamAssignment';
import { Chip } from '../../atoms/Chip';
import { Skeleton } from '@securecodewarrior/design-system-react';
import { Tooltip } from '../../../../common/Tooltip';
import { getEditingDisabledTooltip } from '../../../utils';
export const GoalEditorUserAssignmentSection = ({ assignedTeamIds, status, editingDisabled, skeletonMode, companyTeams, onSelectionEdit, }) => {
    const theme = useTheme();
    const { t, i18n } = useTranslation();
    const [dialogOpen, setDialogOpen] = useState(false);
    const readOnly = !!skeletonMode || editingDisabled;
    const readOnlyTooltip = getEditingDisabledTooltip(editingDisabled, status, t, i18n.format);
    const assignedActiveTeamNames = useMemo(() => assignedTeamIds
        .map((teamId) => { var _a; return ((_a = companyTeams.find((companyTeam) => companyTeam.id === teamId)) === null || _a === void 0 ? void 0 : _a.name) || ''; })
        .filter((name) => !!name)
        .sort(), [assignedTeamIds, companyTeams]);
    const deletedOrDisabledCount = assignedTeamIds.length - assignedActiveTeamNames.length;
    const visibleTeamNameCount = deletedOrDisabledCount ? 9 : 10;
    const { logAnalyticsEvent } = usePlatformContext();
    const analytics = useMemo(() => goalDetailsAnalytics(logAnalyticsEvent), [logAnalyticsEvent]);
    const handleOpenTeamAssignmentDialog = () => {
        setDialogOpen(true);
        analytics.onTeamAssignmentDialogOpened(status, assignedActiveTeamNames.length, companyTeams.length);
    };
    const handleCloseTeamAssignmentDialog = () => {
        setDialogOpen(false);
        analytics.onTeamAssignmentyDialogClosed(status);
    };
    return (_jsxs(_Fragment, { children: [_jsx(GoalEditorSection, { children: _jsxs(Box, { sx: { flex: 1 }, children: [_jsx(Typography, { component: "h2", variant: "h5", sx: (theme) => ({
                                marginBottom: theme.spacing(10),
                                lineHeight: theme.typography.pxToRem(18),
                            }), children: t('goalsAdmin.pages.list.tableHeader.participants') }), _jsxs(Stack, { direction: "row", spacing: 3, sx: {
                                padding: theme.spacing(5, 4.5, 5, 5),
                                border: `1px solid ${theme.palette.badge.accent4.border}`,
                                background: theme.palette.badge.accent4.fill,
                                marginBottom: theme.spacing(10),
                                borderRadius: 3,
                                alignItems: 'center',
                            }, children: [_jsx(InfoIcon, { sx: {
                                        fontSize: theme.typography.pxToRem(24),
                                        color: theme.palette.clickable.default,
                                    } }), _jsx(Typography, { sx: { color: theme.palette.clickable.default }, children: _jsx(Trans, { i18nKey: "goalsAdmin.components.organisms.goalEditor.sections.userAssignment.bannerExplanation" }) })] }), _jsxs(GoalEditorSubsection, { children: [_jsxs(Box, { sx: { boxSizing: 'border-box', width: { xs: '100%', lg: theme.typography.pxToRem(300) } }, children: [_jsx(Typography, { component: "h3", variant: "h6", sx: { marginBottom: theme.spacing(4), lineHeight: theme.typography.pxToRem(18) }, children: t('goalsAdmin.components.organisms.goalEditor.sections.userAssignment.assignByTeams') }), _jsx(Typography, { component: "p", variant: "subtitle1", fontSize: theme.typography.pxToRem(14), lineHeight: theme.typography.pxToRem(20), children: t('goalsAdmin.components.organisms.goalEditor.sections.userAssignment.assignByTeamsCaption') })] }), _jsxs(Box, { sx: {
                                        display: 'flex',
                                        flexWrap: 'wrap',
                                        gap: theme.spacing(2),
                                        flex: 1,
                                        boxSizing: 'border-box',
                                    }, children: [assignedActiveTeamNames.slice(0, visibleTeamNameCount).map((teamName, index) => (_jsx(Tooltip, { title: teamName, arrow: true, children: _jsx(Box, { sx: { maxWidth: '430px', height: 'fit-content' }, children: _jsx(Chip, { label: teamName }) }) }, `${teamName}:${index}`))), deletedOrDisabledCount > 0 && (_jsx(Chip, { color: theme.palette.text.status.error, label: t('goalsAdmin.components.organisms.goalEditor.sections.userAssignment.teamsDeletedCount', {
                                                count: deletedOrDisabledCount,
                                            }) }, "Disabled or deleted teams")), assignedActiveTeamNames.length > visibleTeamNameCount && (_jsx(Tooltip, { arrow: true, title: _jsx(Stack, { direction: "column", alignItems: "flex-start", children: assignedActiveTeamNames.slice(visibleTeamNameCount).map((teamName, index) => (_jsx("span", { children: teamName }, `${teamName}:${index}`))) }), children: _jsx(Box, { children: _jsx(Chip, { color: theme.palette.text.title, label: `+${t('goalsAdmin.components.organisms.goalEditor.sections.userAssignment.teamsCount', { count: assignedActiveTeamNames.length - visibleTeamNameCount })}` }, "Rest of teams") }) })), skeletonMode === 'edit' && (_jsxs(_Fragment, { children: [_jsx(Skeleton, { variant: "rounded", width: "180px", height: "36px" }), _jsx(Skeleton, { variant: "rounded", width: "210px", height: "36px" }), _jsx(Skeleton, { variant: "rounded", width: "160px", height: "36px" })] })), _jsx(Tooltip, { title: readOnlyTooltip, arrow: true, children: _jsx(Box, { sx: { height: 'fit-content' }, children: _jsx(Button, { sx: { height: theme.spacing(9) }, startIcon: assignedActiveTeamNames.length === 0 && deletedOrDisabledCount === 0 ? (_jsx(AddIcon, { sx: (theme) => ({ fontSize: theme.typography.pxToRem(18) }) })) : (_jsx(EditIcon, { sx: (theme) => ({ fontSize: theme.typography.pxToRem(18) }) })), disabled: readOnly, onClick: handleOpenTeamAssignmentDialog, children: assignedActiveTeamNames.length === 0 && deletedOrDisabledCount === 0 && skeletonMode !== 'edit'
                                                        ? t('goalsAdmin.components.organisms.goalEditor.sections.userAssignment.selectTeamsTitle')
                                                        : t('goalsAdmin.components.organisms.goalEditor.sections.objectives.subsections.vulnerabilitiesAndConcepts.editButton') }) }) })] })] })] }) }), _jsx(Dialog, { open: dialogOpen, fullWidth: true, maxWidth: "md", scroll: "body", onClose: handleCloseTeamAssignmentDialog, sx: (theme) => ({
                    '& .MuiPaper-root:not(.MuiAlert-root)': {
                        border: `1px solid ${theme.palette.container.border.default}`,
                        borderRadius: 3,
                        maxHeight: '720px',
                    },
                }), children: _jsx(TeamAssignment, { assignedTeamIds: assignedTeamIds, companyTeams: companyTeams, goalStatus: status, onConfirm: (updatedTeamIds) => {
                        setDialogOpen(false);
                        analytics.onTeamAssignmentUpdated(status, assignedTeamIds, updatedTeamIds, companyTeams.length);
                        onSelectionEdit === null || onSelectionEdit === void 0 ? void 0 : onSelectionEdit(updatedTeamIds);
                    }, onCancel: () => {
                        setDialogOpen(false);
                        handleCloseTeamAssignmentDialog();
                    } }) })] }));
};
