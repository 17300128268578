import angular from 'angular';
import { USER_ROLES } from '../auth/constants';
import { react_router_states } from './enums';

export const MODULE = angular.module('SCWReact', []).name;
export default MODULE;
const app = angular.module(MODULE);

app.controller('SCWReactController', [
  '$scope',
  '$rootScope',
  '$window',
  '$browser',
  '$state',
  '$stateParams',
  '$location',
  'Session',
  'FeatureFlagsApi',
  'FeatureFlags',
  'AnalyticsService',
  function (
    $scope,
    $rootScope,
    $window,
    $browser,
    $state,
    $stateParams,
    $location,
    Session,
    FeatureFlagsApi,
    FeatureFlags,
    AnalyticsService
  ) {
    $scope.hashQueryParams = $location.search();
    // Required to prevent angular from encoding the URL on a refresh which breaks React navigation
    $rootScope.$on('$stateChangeStart', function (_event, next, nextParams, from, fromParams) {
      if (
        Object.values(react_router_states).includes(next.name) &&
        next.name === from.name &&
        nextParams.path &&
        fromParams.path &&
        nextParams.path === fromParams.path // check is needed to allow i.e. #/explore/video/id1 to #/explore/video/id2 navigation
      ) {
        _event.preventDefault();
      }
    });

    const routes = {
      '#/explore': react_router_states.explore,
      '#/assessments/multi': react_router_states.multiassessments,
      '#/admin/goals': react_router_states.goalsAdmin,
      '#/success-hub/': react_router_states.successHub,
      '#/engagement': react_router_states.engagement,
      '#/unsubscribe': react_router_states.unsubscribe,
      '#/communications': react_router_states.communications,
      '#/quests': react_router_states.quests,
      '#/learn': react_router_states.learn,
      '#/cybermon': react_router_states.cybermon,
    };
    const url = $location.absUrl();
    $scope.actualState = react_router_states.quests; // Default state
    for (let route in routes) {
      if (url.includes(route)) {
        $scope.actualState = routes[route];
        break;
      }
    }

    $scope.flagsEnabled = [];
    const ffPromises = [];

    if ([react_router_states.explore, react_router_states.quests].includes($scope.actualState) && Session.getUser()) {
      ffPromises.push(
        FeatureFlagsApi.isFeatureEnabled(FeatureFlags.QUESTS).then((result) => {
          if (result) {
            $scope.flagsEnabled.push(FeatureFlags.QUESTS);
            return;
          }
          // We need to redirect only if you are trying to access the quests page
          // Otherwise we just check the feature flag as it is being used in explore to toggle a feature
          if ($scope.actualState === react_router_states.quests) {
            $state.go('home');
          }
        })
      );
    }
    if ($scope.actualState === react_router_states.learn) {
      ffPromises.push(
        FeatureFlagsApi.isFeatureEnabled(FeatureFlags.QUESTS_V3).then((result) => {
          if (result) {
            $scope.flagsEnabled.push(FeatureFlags.QUESTS_V3);
          }
        })
      );
    }

    if ($scope.actualState === react_router_states.quests) {
      ffPromises.push(
        FeatureFlagsApi.isFeatureEnabled('objectives-sidenav').then((result) => {
          if (result) {
            $scope.flagsEnabled.push('objectives-sidenav');
          }
        })
      );
    }

    if ($scope.actualState === react_router_states.multiassessments) {
      ffPromises.push(
        FeatureFlagsApi.isFeatureEnabled(FeatureFlags.MULTI_ASSESSMENTS).then((result) => {
          if (result) {
            $scope.flagsEnabled.push(FeatureFlags.MULTI_ASSESSMENTS);
            return;
          }
          $state.go('home');
        })
      );
    } else if ($scope.actualState === react_router_states.goalsAdmin) {
      ffPromises.push(
        FeatureFlagsApi.isFeatureEnabled(FeatureFlags.GOALS_ADMIN).then((result) => {
          if (result) {
            $scope.flagsEnabled.push(FeatureFlags.GOALS_ADMIN);
            return;
          }
          $state.go('home');
        })
      );
      ffPromises.push(
        FeatureFlagsApi.isFeatureEnabled(FeatureFlags.GOALS_ADMIN_TEAMS).then((result) => {
          if (result) {
            $scope.flagsEnabled.push(FeatureFlags.GOALS_ADMIN_TEAMS);
            return;
          }
        })
      );
    }
    if ($scope.actualState === react_router_states.communications) {
      ffPromises.push(
        FeatureFlagsApi.isFeatureEnabled(FeatureFlags.COMMS_CENTRE_QUILL).then((result) => {
          if (result) {
            $scope.flagsEnabled.push(FeatureFlags.COMMS_CENTRE_QUILL);
            return;
          }
        })
      );

      ffPromises.push(
        FeatureFlagsApi.isFeatureEnabled(FeatureFlags.GOALS_ADMIN).then((result) => {
          if (result) {
            $scope.flagsEnabled.push(FeatureFlags.GOALS_ADMIN);
          }
        })
      );
    }

    if ($scope.actualState === react_router_states.cybermon) {
      const customerType = Session.getUser().properties.company.customerType;
      // PLAT-14836 Block Trial companies from accessing Cybermon
      if (customerType === 'trial' || customerType === 'product_trial') {
        $state.go('home');
      }

      ffPromises.push(
        FeatureFlagsApi.isFeatureEnabled(FeatureFlags.CYBERMON).then((result) => {
          if (result) {
            $scope.flagsEnabled.push(FeatureFlags.CYBERMON);
            return;
          }
          $state.go('home');
        })
      );
    }

    $scope.featureflagsChecked = false;
    Promise.allSettled(ffPromises).finally(() => {
      $scope.featureflagsChecked = true;
    });

    $scope.onUnAuthenticated = () => {
      // When loading a page and directly being unauthenticated we need to set the requestedState by default.
      $rootScope.requestedState = $scope.actualState;
      if (Object.values(react_router_states).includes($rootScope.requestedState)) {
        $rootScope.requestedStateParams = {
          path: $location.url().substr($location.url().indexOf($scope.actualState) + $scope.actualState.length),
        };
      }
      $rootScope.$broadcast('auth-not-authenticated');
    };

    $scope.sessionId = Session.getSessionId();
    $scope.sessionUser = Session.getUser();
    $scope.apiEndpoint = $window.SCW_ENV.ApiEndpoint;
    $scope.metadata = $rootScope.metadata;
    $scope.analyticsService = AnalyticsService;

    $scope.navigator = {
      navigate: (path, replace) => {
        let nextState;
        let nextSubPath;
        for (const [route, state] of Object.entries(routes)) {
          const r = route.slice(1);
          if (path.startsWith(r)) {
            nextState = state;
            nextSubPath = path.substring(r.length);
            break;
          }
        }

        // when transitioning to another angularJs state we need to make sure a state transition happens so the rest of the app is in sync (i.e. platform header)
        // $state.go can't be used because we can't pass an entire url with query params (they'll get encoded)
        // setting $window.location will trigger a state transition causing an angular controller & scw-react app reload.
        if (nextState && nextState !== $scope.actualState) {
          $window.location = `${$window.location.origin}/#${path}`;
          return;
        }

        // if nextstate is undefined it means you're transitioning to an outside link or part of platform not handled by scw-react
        // in this case we need to determine if it's an outside link or part of the platform routes
        // leaving a simple example in comment as it isn't a requirement or use case anywhere in scw-react at the moment
        // if (!nextState) {
        //   if (path.startsWith('https://')) {
        //     $window.location = path;
        //   else {
        //     $window.location = `${$window.location.origin}/#${path}`;
        //   }
        // }

        $rootScope.requestedState = $scope.actualState;
        $rootScope.requestedStateParams = { path: nextSubPath };
        console.debug('In DEEP navigator', {
          path,
          nextSubPath,
          scope_actualState: $scope.actualState,
          replace,
          reload: $rootScope.reloadPage,
        });
        $stateParams.path = nextSubPath;
        // $browser.url allows a url change without triggering a state change
        $browser.url('#' + path, replace, { path: nextSubPath });
      },
      reload: $rootScope.reloadPage,
    };

    $scope.setRequestedState = (state, params) => {
      $rootScope.requestedState = state;
      $rootScope.requestedStateParams = params;
    };

    $scope.goToState = (state, parameters) => {
      $state.go(state, parameters);
    };
  },
]);

const createRoute =
  (route, translationKey, needsAuthorization = true) =>
  (stateProvider) => {
    stateProvider.state(route, {
      url: `/${route}{path:reactPath}`,
      controller: 'SCWReactController',
      reloadOnSearch: false,
      data: {
        translateKey: translationKey || route.toUpperCase(),
        authorizedRoles: needsAuthorization
          ? [
              USER_ROLES.admin,
              USER_ROLES.reseller,
              USER_ROLES.companyAdmin,
              USER_ROLES.manager,
              USER_ROLES.player,
              USER_ROLES.assessmentUser,
            ]
          : undefined,
      },
      template: `
        <scw-react-app
          ng-if="featureflagsChecked"
          amplitude="analyticsService"
          metadata="metadata"
          api-endpoint="apiEndpoint"
          session-id="sessionId"
          session-user="sessionUser"
          navigator="navigator"
          go-to-state="goToState"
          set-requested-state="setRequestedState"
          on-un-authenticated="onUnAuthenticated"
          flags-enabled="flagsEnabled"
          hash-query-params="hashQueryParams"
          has-cookie-policy="hasCookiePolicy"
        />`.trim(),
    });
  };

// In some circumstances the angular ui-router will try to force sync the url
// by default it encodes the path parameter, this type ensures it uses the value as we stored it.
app.config([
  '$urlMatcherFactoryProvider',
  function ($urlMatcherFactoryProvider) {
    $urlMatcherFactoryProvider.type('reactPath', {
      encode: (val) => val,
      decode: (val) => val,
    });
  },
]);

app.config([
  '$stateProvider',
  function ($stateProvider) {
    createRoute('quests', 'QUESTS')($stateProvider);
    createRoute('learn', 'LEARN')($stateProvider);
    createRoute('explore', 'EXPLORE', false)($stateProvider);
    createRoute('assessments/multi', 'BULK_EDIT_ASSESSMENTS')($stateProvider);
    createRoute('admin/goals', 'LEARNING_GOALS')($stateProvider);
    createRoute('success-hub', 'SUCCESS_HUB')($stateProvider);
    createRoute('communications', 'COMMUNICATIONS')($stateProvider);
    createRoute('engagement', 'ENGAGEMENT')($stateProvider);
    createRoute('cybermon', 'CYBERMON')($stateProvider);
    createRoute('unsubscribe', 'UNSUBSCRIBE', false)($stateProvider); // No login required
  },
]);
