import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { Box, Typography } from '@mui/material';
import { CybermonAvatarImage, CybermonAchievementCard, AchievementGrid } from '../styled';
import { StatusBadge } from './StatusBadge';
import { useTranslation } from 'react-i18next';
const getContentFromProps = (props, t
// eslint-disable-next-line sonarjs/cognitive-complexity
) => {
    const imageKey = props.earned && props.discovered ? 'detail' : props.discovered ? 'discovered' : 'undiscovered';
    const image = props.images[imageKey].thumb;
    if (props.name === 'Cyberhero') {
        return {
            displayName: 'Cyberhero',
            statusText: t('defeatAllFour'),
            status: props.earned ? 'completed' : 'undiscovered',
            displayNameColor: props.earned ? 'text.title' : 'text.soft',
            image,
        };
    }
    if (props.earned && props.discovered) {
        return {
            displayName: props.name,
            statusText: t('defeated'),
            status: 'completed',
            displayNameColor: 'text.title',
            image,
        };
    }
    if (props.discovered) {
        return {
            displayName: props.name,
            statusText: props.active ? `${t('week')} ${props.week}` : t('missed'),
            status: props.active ? (props.attempted ? 'in_progress' : 'not_started') : 'missed',
            displayNameColor: 'text.title',
            image,
        };
    }
    return {
        displayName: t('undiscovered'),
        statusText: `${t('week')} ${props.week}`,
        status: 'undiscovered',
        displayNameColor: 'text.soft',
        image,
    };
};
export const CybermonAchievement = (props) => {
    const { t } = useTranslation('react_components', { keyPrefix: 'pages.cybermon.badges' });
    const { image, status, displayName, statusText, displayNameColor } = getContentFromProps(props, t);
    return (_jsxs(CybermonAchievementCard, { sx: { overflow: 'hidden' }, children: [_jsx(Box, { sx: { borderRight: (theme) => `1px solid ${theme.palette.container.border.default}`, height: '100%' }, children: _jsx(CybermonAvatarImage, { src: image, alt: displayName }) }), _jsxs(AchievementGrid, { children: [_jsxs(Box, { gridArea: "content", children: [_jsx(Typography, { variant: "subtitle1", color: displayNameColor, sx: { wordBreak: 'break-all' }, children: displayName }), _jsx(Typography, { variant: "caption", color: "text.soft", children: statusText })] }), _jsx(StatusBadge, { status: status })] })] }));
};
