export const react_router_states = {
  quests: 'quests',
  explore: 'explore',
  learn: 'learn',
  multiassessments: 'assessments/multi',
  goalsAdmin: 'admin/goals',
  successHub: 'success-hub',
  engagement: 'engagement',
  unsubscribe: 'unsubscribe',
  communications: 'communications',
  cybermon: 'cybermon',
};
