import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { WithFooter } from '../footer/WithFooter';
export const LearnPage = () => {
    return (_jsx(WithFooter, { children: _jsxs("div", { style: {
                display: 'flex',
                alignItems: 'center',
                flexDirection: 'column',
                flexGrow: 1,
                justifyContent: 'center',
            }, children: [_jsx("h1", { children: "Learn page" }), _jsx("p", { children: "Placeholder for the new learn page" })] }) }));
};
