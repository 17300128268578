import { jsx as _jsx, Fragment as _Fragment, jsxs as _jsxs } from "react/jsx-runtime";
import { GoalEditorObjectivesSection } from './GoalEditorObjectivesSection';
import { GoalEditorGeneralAndBadgeSection } from './GoalEditorGeneralAndBadgeSection';
import { GoalEditorOtherSettingsSection } from './GoalEditorOtherSettingsSection';
import { GoalEditorUserAssignmentSection } from './GoalEditorUserAssignmentSection';
import SnackbarAlert from '../../molecules/SnackbarAlert';
import { useState } from 'react';
import { getInitialSnackbarState } from '../../../utils';
const GoalEditor = ({ data, availableCategories, validationErrors, editingDisabled, teamAssignmentEnabled, companyTeams, onEdit, }) => {
    const selectedCategorySlugs = data.objectives.map((o) => `${o.rootCategoryId}.${o.categoryId}.${o.subcategoryId}`);
    const [snackbarConfig, setSnackbarConfig] = useState(getInitialSnackbarState());
    const triggerSnackbar = (message, severity) => {
        setSnackbarConfig({ message, severity, open: true, key: new Date().getTime() });
    };
    const handleCloseSnackbar = () => {
        setSnackbarConfig((prev) => (Object.assign(Object.assign({}, prev), { open: false })));
    };
    return (_jsxs(_Fragment, { children: [_jsx(GoalEditorGeneralAndBadgeSection, { name: data.name, badge: data.badge, status: data.status, isMandatory: data.isMandatory, validationErrors: validationErrors, editingDisabled: editingDisabled, onNameEdit: (name) => onEdit(Object.assign(Object.assign({}, data), { name })), onIsMandatoryToggle: () => onEdit(Object.assign(Object.assign({}, data), { isMandatory: !data.isMandatory })), onBadgeEdit: (badge) => onEdit(Object.assign(Object.assign({}, data), { badge })), onBadgeError: (error) => triggerSnackbar(error.message, 'error') }), teamAssignmentEnabled && (_jsx(GoalEditorUserAssignmentSection, { status: data.status, editingDisabled: editingDisabled, assignedTeamIds: data.assignedTeamIds, companyTeams: companyTeams, onSelectionEdit: (assignedTeamIds) => onEdit(Object.assign(Object.assign({}, data), { assignedTeamIds })) })), _jsx(GoalEditorObjectivesSection, { selectedCategories: selectedCategorySlugs, availableCategories: availableCategories, status: data.status, editingDisabled: editingDisabled, onSelectionEdit: (newCategorySlugSelection) => {
                    const objectives = newCategorySlugSelection.map((categorySlug, index) => {
                        const [rootCategoryId, categoryId, subcategoryId] = categorySlug.split('.');
                        return {
                            rootCategoryId,
                            categoryId,
                            subcategoryId,
                            objectiveNumber: index,
                            kind: 'learning-outcome',
                        };
                    });
                    onEdit(Object.assign(Object.assign({}, data), { objectives }));
                } }), _jsx(GoalEditorOtherSettingsSection, { startDate: data.startDate, endDate: data.endDate, status: data.status, validationErrors: validationErrors, editingDisabled: editingDisabled, onStartDateEdit: (startDate) => onEdit(Object.assign(Object.assign({}, data), { startDate })), onEndDateEdit: (endDate) => onEdit(Object.assign(Object.assign({}, data), { endDate })) }), _jsx(SnackbarAlert, { message: snackbarConfig.message, severity: snackbarConfig.severity, open: snackbarConfig.open, onClose: handleCloseSnackbar }, snackbarConfig.key)] }));
};
export default GoalEditor;
