import { jsx as _jsx } from "react/jsx-runtime";
import { styled, Typography, useTheme } from '@mui/material';
const CustomChip = styled('div')(({ theme }) => ({
    paddingTop: theme.spacing(1),
    paddingBottom: theme.spacing(1),
    paddingLeft: theme.spacing(3),
    paddingRight: theme.spacing(3),
    border: `1px solid ${theme.palette.container.border.default}`,
    borderRadius: 2 * theme.shape.borderRadius,
    height: theme.spacing(9),
    whiteSpace: 'nowrap',
    display: 'flex',
    alignItems: 'center',
    '& > .MuiTypography-root': {
        textOverflow: 'ellipsis',
        overflow: 'hidden',
    },
}));
export const Chip = ({ label, color }) => {
    const theme = useTheme();
    return (_jsx(CustomChip, { children: _jsx(Typography, { variant: "button", color: color || theme.palette.text.soft, children: label }) }));
};
