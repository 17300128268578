import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { Box, Stack, Typography } from '@mui/material';
import { useCybermon } from '../hooks/useCybermon';
import { AchievementGrid, CybermonAchievementCard, CybermonAvatarImage } from '../styled';
import { Rive } from '../../common/Rive/Rive';
import { StatusBadge } from './StatusBadge';
import { Link } from '@securecodewarrior/design-system-react/lib/wanda';
import { Card } from '@securecodewarrior/design-system-react/lib/labs';
import { useTranslation } from 'react-i18next';
const TrophyImage = ({ img, name }) => (_jsx(Box, { height: "100%", maxWidth: 80, sx: { borderRight: (theme) => `1px solid ${theme.palette.container.border.default}` }, children: img.endsWith('riv') ? _jsx(Rive, { src: img }) : _jsx(CybermonAvatarImage, { src: img, alt: name }) }));
const Trophy = ({ name, images, earned }) => {
    const { t } = useTranslation('react_components', { keyPrefix: 'pages.cybermon.results' });
    return (_jsxs(CybermonAchievementCard, { overflow: "hidden", maxWidth: 344, children: [_jsx(TrophyImage, { img: images[earned ? 'detail' : 'undiscovered'].thumb, name: name }), _jsxs(AchievementGrid, { children: [_jsxs(Box, { gridArea: "content", children: [_jsx(Typography, { variant: "subtitle1", color: earned ? 'text.title' : 'text.soft', sx: { wordBreak: 'break-all' }, children: name }), _jsx(Typography, { variant: "caption", color: "text.soft", children: earned ? t('defeated') : t('missed') })] }), _jsx(StatusBadge, { status: earned ? 'completed' : 'missed' })] })] }));
};
const HighlightedText = ({ children }) => (_jsx(Typography, { variant: "h2", component: "span", color: (theme) => theme.palette.text.status.success, children: children }));
const Message = ({ missed }) => {
    const { cyberHero } = useCybermon();
    const { t } = useTranslation('react_components', { keyPrefix: 'pages.cybermon.results' });
    const { t: finalState } = useTranslation('react_components', { keyPrefix: 'pages.cybermon.finalState' });
    if (cyberHero === null || cyberHero === void 0 ? void 0 : cyberHero.earned) {
        return (_jsxs(Stack, { textAlign: "center", alignItems: "center", gap: (theme) => theme.spacing(8), children: [_jsx(Box, { maxHeight: 182, pt: 4, children: _jsx("img", { src: "https://media.securecodewarrior.com/achievements/img/cyberhero/cybermon-final-state-all.png", alt: finalState('all.altText'), style: { maxHeight: 270, objectFit: 'cover' } }) }), _jsxs(Typography, { variant: "h2", children: [t('allHailThe'), " ", _jsx("br", {}), _jsx(HighlightedText, { children: "Cyberhero" }), "!"] }), _jsxs(Typography, { sx: { textWrap: 'pretty' }, children: [t('youHaveDefeatedAll'), " Cybermon ", t('andHaveEarnedTheTitle'), " Cyberhero."] }), _jsx(Typography, { sx: { textWrap: 'pretty' }, children: t('viewAllBadges') })] }));
    }
    if (missed) {
        return (_jsxs(Stack, { textAlign: "center", alignItems: "center", gap: (theme) => theme.spacing(10), children: [_jsxs(Typography, { variant: "h2", children: [t('the'), " ", _jsx(HighlightedText, { children: "Cybermon" }), " ", t('haveLeft')] }), _jsx(Typography, { sx: { textWrap: 'pretty' }, children: t('cyberSecurityMonthOver') }), _jsx(Typography, { sx: { textWrap: 'pretty' }, children: t('youCanStillExplore') })] }));
    }
    return (_jsxs(Stack, { textAlign: "center", alignItems: "center", gap: (theme) => theme.spacing(10), children: [_jsx(Box, { maxHeight: 182, pt: 4, children: _jsx("img", { src: "https://media.securecodewarrior.com/achievements/img/cyberhero/cybermon-final-state-some.png", alt: finalState('some.altText'), style: { maxHeight: 270, objectFit: 'cover' } }) }), _jsxs(Typography, { variant: "h2", children: [t('thankYouForDefendingAgainst'), " ", _jsx("br", {}), _jsx(HighlightedText, { children: "Cybermon" }), "!"] }), _jsx(Typography, { sx: { textWrap: 'pretty' }, children: t('viewAllBadges') })] }));
};
export const ResultsScreen = () => {
    const { cybermon } = useCybermon();
    const missed = !cybermon.some(({ earned }) => earned);
    const { t } = useTranslation('react_components', { keyPrefix: 'pages.cybermon.results' });
    const cardPadding = missed ? 23.5 : 5; // large padding for the missed state
    return (_jsxs(Card, { sx: {
            background: (theme) => theme.palette.container.fill.card2,
            paddingTop: cardPadding,
            paddingBottom: 23.5,
            px: 5,
        }, display: "flex", alignItems: "center", height: "fit-content", flexDirection: "column", gap: 10, children: [_jsx(Message, { missed: missed }), _jsx(Box, { children: _jsx(Stack, { direction: "row", flexWrap: "wrap", justifyContent: "center", maxWidth: 750, gap: 4, margin: "auto", children: cybermon.map(({ _id, name, custom_data: { images }, earned, image }) => (_jsx(Trophy, { image: image, name: name, earned: earned, images: images }, _id))) }) }), _jsx(Stack, { alignItems: "center", p: 3.5, children: _jsx(Link, { href: "#/explore", rel: "noopener noreferrer", textAlign: "center", children: t('continueOnYourSecureCodingJourney') }) })] }));
};
