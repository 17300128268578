import { jsx as _jsx, Fragment as _Fragment, jsxs as _jsxs } from "react/jsx-runtime";
import { Box, Typography, useTheme } from '@mui/material';
import { Skeleton } from '@securecodewarrior/design-system-react';
import { Drawer } from '@securecodewarrior/design-system-react/lib/wanda';
import { useEffect, useMemo, useRef, useState } from 'react';
import { usePlatformContext } from '../platformContext';
import { ObjectiveElement } from './ObjectiveElement';
import { Trans, useTranslation } from 'react-i18next';
import { GoalElement } from './GoalElement';
import { Divider } from '../Divider';
import { Link } from '../navigation';
import { getCategoryName } from '../utils/mapper';
import { goalAnalytics } from './Goals.analytics';
const isCategoryObjective = (objective) => objective && objective.kind && objective.kind === 'categories';
export const WithGoalsSidenav = (props) => {
    var _a;
    const { fetchGoals } = props;
    const refIsInitialExpandedEvent = useRef(true);
    const { flagsEnabled, sessionUser, setTranslatedCategories, metadata, logAnalyticsEvent } = usePlatformContext();
    const analytics = goalAnalytics(logAnalyticsEvent);
    const [expanded, setExpanded] = useState(false);
    const [goals, setGoals] = useState();
    const didFetchObjectives = useRef(false);
    const [t] = useTranslation();
    const isFlagEnabled = () => flagsEnabled.includes('objectives-sidenav');
    const [flagEnabled, setFlagEnabled] = useState(isFlagEnabled());
    const theme = useTheme();
    const sx = useMemo(() => createStyle(theme), [theme]);
    const fetch = () => {
        if (!didFetchObjectives.current && flagEnabled) {
            didFetchObjectives.current = true;
            fetchGoals().then((data) => {
                const categories = Object.fromEntries(data.flatMap((goal) => goal.objectives
                    .filter(isCategoryObjective)
                    .flatMap((objective) => objective.categories.map((category) => category.categoryId))
                    .map((categoryIds) => [categoryIds, getCategoryName(categoryIds, metadata)])));
                setTranslatedCategories((prev) => (Object.assign(Object.assign({}, prev), categories)));
                setGoals(data);
            });
        }
    };
    useEffect(() => {
        didFetchObjectives.current = false;
        // undefined to trigger skeleton
        setGoals(undefined);
        if (expanded) {
            fetch();
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [fetchGoals]);
    // the feature flag might've not been loaded yet on first render (after a refresh), so recheck after 2s.
    useMemo(() => {
        setTimeout(() => {
            if (!flagEnabled && isFlagEnabled()) {
                setFlagEnabled(true);
            }
        }, 2000);
    }, []); // eslint-disable-line react-hooks/exhaustive-deps
    if (!sessionUser || sessionUser.roles.includes('scw admin') || !((_a = sessionUser.properties) === null || _a === void 0 ? void 0 : _a.company) || !flagEnabled) {
        return props.children;
    }
    const companyAdmin = sessionUser.roles.some((i) => i === 'company admin');
    const onExpandedStateChanged = (expanded) => {
        setExpanded(expanded);
        if (expanded) {
            fetch();
        }
        if (!refIsInitialExpandedEvent.current) {
            analytics.onGoalDrawerExpandToggle(expanded);
        }
        refIsInitialExpandedEvent.current = false;
    };
    const mandatoryGoals = (goals === null || goals === void 0 ? void 0 : goals.filter((goal) => goal.isMandatory)) || [];
    const optionalGoals = (goals === null || goals === void 0 ? void 0 : goals.filter((goal) => !goal.isMandatory)) || [];
    const createGoalElement = (g, key) => (_jsx(GoalElement, { imageUrl: g.imageUrl, title: g.name, deadline: g.deadline, completedAt: g.completedAt, children: g.objectives.map((o, i) => (_jsx(ObjectiveElement, { objective: o, currentModule: props.currentModule }, i))) }, key));
    return (_jsxs(Box, { sx: sx, children: [_jsxs(Drawer, { name: "objectives-sidenav", contentDescription: t('goals.sidebar.title'), width: theme.typography.pxToRem(400), top: "64px", collapsibleOnWidescreen: true, onExpandedStateChanged: onExpandedStateChanged, children: [_jsx(Typography, { variant: "h3", component: "h2", className: "sidebar-paragraph", children: _jsx(Trans, { i18nKey: "goals.sidebar.title" }) }), goals ? (_jsxs(_Fragment, { children: [mandatoryGoals.length !== 0 && (_jsxs(_Fragment, { children: [_jsx(Divider, {}), _jsx(Typography, { component: "h3", variant: "h6", children: t('goals.sidebar.mandatory') }), mandatoryGoals.map((goal, i) => createGoalElement(goal, i))] })), optionalGoals.length !== 0 && (_jsxs(_Fragment, { children: [_jsx(Divider, {}), _jsx(Typography, { component: "h3", variant: "h6", children: t('goals.sidebar.optional') }), optionalGoals.map((goal, i) => createGoalElement(goal, i))] }))] })) : (_jsxs("div", { className: "goal-skeletons", children: [_jsx(Skeleton, { component: "div" }), _jsx(Skeleton, { component: "div", sx: { opacity: '.6' } }), _jsx(Skeleton, { component: "div", sx: { opacity: '.4' } }), _jsx(Skeleton, { component: "div", sx: { opacity: '.2' } })] })), _jsx(Divider, {}), _jsx(Typography, { className: "sidebar-paragraph", children: _jsx(Trans, { i18nKey: "goals.sidebar.info1" }) }), _jsx(Typography, { className: "sidebar-paragraph", children: _jsx(Trans, { i18nKey: "goals.sidebar.info2" }) }), companyAdmin ? (_jsx(Typography, { className: "sidebar-paragraph soft", children: _jsx(Trans, { i18nKey: "goals.sidebar.info4", children: _jsx(Link, { to: "/admin/goals", children: null }) }) })) : (_jsx(Typography, { className: "sidebar-paragraph soft", children: _jsx(Trans, { i18nKey: "goals.sidebar.info3", children: _jsx(Link, { to: "/explore", children: null }) }) }))] }), _jsx("div", { className: "contentwrapper", children: props.children })] }));
};
const createStyle = (theme) => ({
    display: 'flex',
    position: 'relative',
    '.contentwrapper': {
        flexGrow: '1',
        display: 'flex',
    },
    '.drawer': {
        h3: {
            margin: theme.spacing(5, 7, 4, 7),
        },
        'h2.sidebar-paragraph': {
            margin: theme.spacing(5, 7, 7.5, 7),
        },
        '.sidebar-paragraph': {
            margin: 5,
            marginTop: 4,
            '&.soft': {
                color: theme.palette.text.soft,
            },
            button: {
                display: 'inline-flex',
                verticalAlign: 'top',
                color: theme.palette.clickable.default,
                '&:hover': {
                    color: theme.palette.text.title,
                },
            },
        },
        hr: {
            marginTop: 5,
        },
        '.goal-skeletons': {
            '> div': {
                marginY: 4,
                marginX: 5,
                borderRadius: 3,
                height: theme.typography.pxToRem(100),
                transform: 'unset',
            },
        },
    },
});
